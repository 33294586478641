import Marker from '../index'
import SentryDirectionMarkerIcon from './SentryDirectionMarkerIcon'

type SentryDirectionMarkerProps = {
	latitude: number
	longitude: number
	bearing: number
}

const SentryDirectionMarker = ({
	latitude,
	longitude,
	bearing,
}: SentryDirectionMarkerProps) => (
	<Marker
		position={[latitude, longitude]}
		icon={<SentryDirectionMarkerIcon bearing={bearing} />}
		iconAnchor={[117, 117]}
	/>
)

export default SentryDirectionMarker
