import { useMemo } from 'react'

import type { SiteInstallation } from '@Store/types'

import {
	CameraSector,
	DisruptorSector,
	DsxSector,
	RadarSensorSector,
	RfSensorSector,
	GenericSensorSector,
} from '@Components/Sectors'

import { useShowSectors } from '@Components/Sectors/useShowSectors'
import { useParams } from 'react-router-dom'
import DsxDisruptSector from '@Components/Sectors/DsxSector/DsxDisruptSector'

type DeviceSectorsProps = {
	installation: SiteInstallation
}

const DeviceSectors = ({ installation }: DeviceSectorsProps) => {
	const { sensorId: sensorIdParam } = useParams()
	const sensorId = Number(sensorIdParam)
	const {
		showCameraSector,
		showDisruptorSector,
		showRadarSector,
		showRfSensorSector,
		showRfOneSensorSector,
		showRfPatrolSensorSector,
		showDsxSensorSector,
		showGenericSensorSector,
	} = useShowSectors(installation)

	const rfSensors = installation.rf_sensors.filter(
		(sensor) =>
			!sensor.model.includes('dsx') &&
			((showRfOneSensorSector && sensor.model === 'rf_one') ||
				(showRfPatrolSensorSector && sensor.model === 'rf_patrol'))
	)

	const dsx = installation.rf_sensors.filter((sensor) =>
		sensor.model.includes('dsx')
	)

	const dsxDisruptors = useMemo(
		() =>
			dsx
				.filter((sensor) => !sensor.model.includes('no_jam'))
				.map((item) => {
					return {
						...item,
						// The reason why we'd required to do this is because BE only sends the updated data inside the Canon object
						// We can do further modify modification, if we really need to
						// but for data integrity, BE should return the correct data
						reach_jamming: item.reach_jamming || item.Cannon?.reach || 0,
					}
				}) || [],
		[dsx]
	)

	const disruptors = installation.disruptors.filter(
		(sensor) => !sensor.cannon_type.includes('DSX')
	)

	const radars = installation.radars
	const cameras = installation.cameras
	const genericSensors = installation.generic_sensors

	return (
		<>
			{showCameraSector &&
				cameras.map((camera) => (
					<CameraSector
						key={camera.id}
						latitude={camera.latitude}
						longitude={camera.longitude}
						bearing={camera.direction}
						max_fov_angle={camera.max_fov_angle}
						min_fov_angle={camera.min_fov_angle}
						pan={camera.ptz_pan}
						zoom={camera.ptz_zoom}
						isActive={sensorId === camera.id}
					/>
				))}
			{disruptors.map(
				(disruptor) =>
					(showDisruptorSector || disruptor.power_trigger_engaged) && (
						<DisruptorSector
							key={disruptor.id}
							cannon_type={disruptor.cannon_type}
							latitude={disruptor.latitude}
							longitude={disruptor.longitude}
							bearing={disruptor.direction}
							reach={disruptor.reach}
							power_trigger_engaged={disruptor.power_trigger_engaged}
							isActive={sensorId === disruptor.id}
						/>
					)
			)}
			{showRadarSector &&
				radars.map((radar) => (
					<RadarSensorSector
						key={radar.id}
						latitude={radar.latitude}
						longitude={radar.longitude}
						radar_az_min_track={radar.radar_az_min_track}
						radar_az_max_track={radar.radar_az_max_track}
						radar_az_min_search={radar.radar_az_min_search}
						radar_az_max_search={radar.radar_az_max_search}
						bearing={radar.direction}
						reach_max={radar.reach_max}
						reach_min={radar.reach_min}
						isActive={sensorId === radar.id}
					/>
				))}
			{showRfSensorSector &&
				rfSensors.map((rfSensor) => (
					<RfSensorSector
						key={rfSensor.id}
						model={rfSensor.model}
						latitude={rfSensor.latitude}
						longitude={rfSensor.longitude}
						bearing={rfSensor.direction}
						reach={rfSensor.reach}
						isActive={sensorId === rfSensor.id}
					/>
				))}
			{showDsxSensorSector &&
				dsx.map((sensor) => (
					<DsxSector
						key={sensor.id}
						latitude={sensor.latitude}
						longitude={sensor.longitude}
						reach={sensor.reach}
						isActive={sensorId === sensor.id}
					/>
				))}
			{dsxDisruptors.map(
				(sensor) =>
					(showDsxSensorSector || sensor.jamming) && (
						<DsxDisruptSector
							key={`${sensor.id}-disruptor`}
							latitude={sensor.latitude}
							longitude={sensor.longitude}
							reach_jamming={sensor.reach_jamming}
							jamming={sensor.jamming}
							isActive={sensorId === sensor.id}
						/>
					)
			)}
			{showGenericSensorSector &&
				genericSensors.map((sensor) => (
					<GenericSensorSector
						key={sensor.id}
						latitude={sensor.latitude}
						longitude={sensor.longitude}
						bearing={sensor.direction}
						angle={sensor.sector_field_of_view}
						reach={sensor.max_range}
						isActive={sensorId === sensor.id}
					/>
				))}
		</>
	)
}

export default DeviceSectors
