import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import {
	selectSiteInstallations,
	useGetSiteLiveQuery,
} from '@Store/sites/sitesWsApi'

// Leaflet
import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'
import SentryDirectionMarker from '@Components/Markers/SentryMarker/SentryDirectionMarker'
import DeviceSectors from '@Components/Site/MapLayers/DeviceSectors'
import SentryTrackHistory from '@/components/Markers/SentryMarker/SentryTrackHistory'
import { COLORS } from '@Constants/colors'
import { useMapEvents } from 'react-leaflet'

// Dragging timeout allows the user to drag for this amount of time before the map re-center
const DRAGGING_TIMEOUT = 5000

// Installations HOC
const Installations = ({ siteId }: { siteId: number }) => {
	const [isDragging, setIsDragging] = useState(false)

	const timer = useRef<NodeJS.Timeout | null>(null)

	const map = useMapEvents({
		dragstart: () => {
			timer.current && clearTimeout(timer.current)
			setIsDragging(true)
		},
		dragend: () => {
			timer.current = setTimeout(() => setIsDragging(false), DRAGGING_TIMEOUT)
		},
	})

	const { installations } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			installations: selectSiteInstallations(data),
		}),
	})

	const anchoredInstallation = useMemo(
		() => installations?.find((installation) => installation.anchored),
		[installations]
	)

	useEffect(() => {
		if (!anchoredInstallation || isDragging) return

		map.setView([anchoredInstallation.latitude, anchoredInstallation.longitude])
	}, [anchoredInstallation, isDragging, map])

	return (
		<>
			{installations?.map((sentry) => (
				<Fragment key={sentry.id}>
					<DeviceSectors installation={sentry} />
					{sentry.location_history && sentry.location_history.length > 0 && (
						<SentryTrackHistory
							positions={sentry.location_history.map((location) => ({
								lat: location.latitude,
								lng: location.longitude,
							}))}
							color={COLORS.GREEN}
						/>
					)}
					{sentry.sentry_type === 'on_the_move' && (
						<SentryDirectionMarker
							latitude={sentry.latitude}
							longitude={sentry.longitude}
							bearing={sentry.direction}
						/>
					)}
					<SentryMarker
						name={sentry.name}
						position={[sentry.latitude, sentry.longitude]}
						sentryType={sentry.sentry_type}
						iconColor={sentry.status_color}
					/>
				</Fragment>
			))}
		</>
	)
}
export default Installations
