import { useTranslation } from 'react-i18next'
import {
	Box,
	Flex,
	Text,
	useRangeSlider,
	NumberInputField,
} from '@chakra-ui/react'
import { type RangeSliderProps } from '@chakra-ui/react'

// Components
import Thumb from './Thumb'
import { useEffect, useState } from 'react'
import SliderNumberInput from '@/components/FormElements/SliderNumberInput/SliderNumberInput'
import { isValidNumber } from '@/utils/numbers'
import AglRangeSliderMeasuringLines from './AglRangeSliderMeasuringLines'

type AglRangeSliderProps = {
	value?: number[]
	defaultValue: any
	min: number
	max: number
	step?: number
	stepByNumber?: number
	minStepsBetweenThumbs?: number
	inputWidth?: number
	// 'aria-label': [string, string]
	onChangeEnd?: RangeSliderProps['onChangeEnd']
	onChange: RangeSliderProps['onChange']
}

const AglRangeSlider = ({
	min,
	max,
	stepByNumber,
	defaultValue,
	minStepsBetweenThumbs = 1,
	...rest
}: AglRangeSliderProps) => {
	const {
		state,
		actions,
		getInnerTrackProps,
		getRootProps,
		getThumbProps,
		getTrackProps,
		getMarkerProps,
	} = useRangeSlider({
		min,
		max,
		defaultValue,
		orientation: 'vertical',
		minStepsBetweenThumbs,
		...rest,
	})

	const [minRange, setMinRange] = useState(String(state.value[0]))
	const [maxRange, setMaxRange] = useState(String(state.value[1]))

	const { t } = useTranslation('forms', { keyPrefix: 'radarAglMaskForm' })

	const { onKeyDown: onThumbKeyDownFirstIndex, ...thumbPropsFirstIndex } =
		getThumbProps({
			index: 0,
		})

	const { onKeyDown: onThumbKeyDownSecondIndex, ...thumbPropsSecondIndex } =
		getThumbProps({
			index: 1,
		})
	const lineValues = [-3000, -2000, -1000, 0, 1000, 2000, 3000]
	const markers = lineValues.map((value) => {
		return getMarkerProps({ value })
	})

	useEffect(() => {
		if (!isValidNumber(Number(minRange))) return

		if (state.value[0] !== Number(minRange) && state.isDragging) {
			setMinRange(String(state.value[0]))
		}
	}, [minRange, state.isDragging, state.value])

	useEffect(() => {
		if (!isValidNumber(Number(maxRange))) return

		if (state.value[1] !== Number(maxRange) && state.isDragging) {
			setMaxRange(String(state.value[1]))
		}
	}, [maxRange, state.isDragging, state.value])

	const topInputMax = max
	const topInputMin = state.value[0] + minStepsBetweenThumbs

	const bottomInputMax = state.value[1] - minStepsBetweenThumbs
	const bottomInputMin = min

	const onKeyDownStepBy = (
		e: React.KeyboardEvent<HTMLDivElement>,
		thumbIndex: number
	) => {
		if (e.code === 'ArrowRight') actions.stepUp(thumbIndex, stepByNumber)
		else if (e.code === 'ArrowLeft') actions.stepDown(thumbIndex, stepByNumber)
		else if (thumbIndex === 0 && typeof onThumbKeyDownFirstIndex === 'function')
			onThumbKeyDownFirstIndex(e)
		else if (
			thumbIndex === 1 &&
			typeof onThumbKeyDownSecondIndex === 'function'
		)
			onThumbKeyDownSecondIndex(e)
	}

	return (
		<Flex h='100%' direction='column' marginInlineStart={3}>
			<Text fontSize='xs' color='label_color'>
				{t('maxHeight')}
			</Text>
			<SliderNumberInput
				min={topInputMin}
				max={topInputMax}
				value={maxRange}
				onChange={(val) => {
					setMaxRange(val)

					const value = Number(val)
					actions.setValueAtIndex(1, value)
				}}
			>
				<NumberInputField
					w={'100%'}
					p={1}
					textAlign={'center'}
					data-testid='input-agl-max'
					paddingBlock={4}
					paddingInline={2}
					h={4}
				/>
			</SliderNumberInput>

			<Flex h='100%' marginBlock={4}>
				<Flex
					paddingInlineEnd={4}
					h='100%'
					w='50%'
					textAlign='center'
					position='relative'
				>
					{markers.map((markerProps, index) => {
						const value = lineValues[index]
						let offset = 0
						if (index === 0 || index === markers.length - 1) {
							index === 0 ? (offset -= 5) : (offset += 5)
						}
						return (
							<Text
								key={index}
								fontSize='sm'
								left={-1}
								width='100%'
								transform={`translateY(${11 + offset}px)`}
								{...markerProps}
							>
								{value}
							</Text>
						)
					})}
				</Flex>

				<Box cursor='pointer' h='100%' {...getRootProps()}>
					<Box w='24px' bgColor='primary' opacity='50%' {...getTrackProps()}>
						<AglRangeSliderMeasuringLines />
						<Box
							w='24px'
							bgColor='radarMasks.fill'
							{...getInnerTrackProps()}
						></Box>
					</Box>
					<Thumb
						value={state.value[0]}
						thumbIndex={0}
						thumbProps={thumbPropsFirstIndex}
						onKeyDownStepBy={onKeyDownStepBy}
						bgColor='primary'
						testId='slider-agl-min'
						yOffset={-1}
					/>
					<Thumb
						value={state.value[1]}
						thumbIndex={1}
						thumbProps={thumbPropsSecondIndex}
						onKeyDownStepBy={onKeyDownStepBy}
						bgColor='primary'
						testId='slider-agl-max'
						yOffset={1}
					/>
				</Box>
			</Flex>
			<Text fontSize='xs' color='label_color'>
				{t('minHeight')}
			</Text>

			<SliderNumberInput
				data-testid='input-agl-min'
				value={minRange}
				min={bottomInputMin}
				max={bottomInputMax}
				onChange={(val) => {
					setMinRange(val)

					const value = Number(val)
					actions.setValueAtIndex(0, value)
				}}
			>
				<NumberInputField
					w={'100%'}
					p={1}
					textAlign={'center'}
					data-testid='input-agl-max'
					paddingBlock={4}
					paddingInline={2}
					h={4}
				/>
			</SliderNumberInput>
		</Flex>
	)
}

export default AglRangeSlider
