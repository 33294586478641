import type { ThreatContribution } from '@/store/types'
import { Flex, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { formatThreatLevel } from '@/utils/formatUtils'

type ThreatContributionsTooltipProps = {
	threatContributions?: ThreatContribution
}

const MaxBandThreshold = 75

const calculateMaxColorBand = (value: number) => {
	return value * MaxBandThreshold
}

const getThreatColorBand = (value: number, maxValue: number) => {
	const thresholdValue = maxValue > 0 ? value * MaxBandThreshold : 0

	if (thresholdValue >= maxValue && thresholdValue > 0) {
		return 'error'
	} else if (
		thresholdValue >= maxValue / 2 &&
		thresholdValue < maxValue &&
		thresholdValue > 0
	) {
		return 'amber.500'
	} else {
		return 'body_text'
	}
}

const ThreatContributionsTooltip = ({
	threatContributions,
}: ThreatContributionsTooltipProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'notifications.detections',
	})

	const contributions = useMemo(
		() =>
			Object.entries(threatContributions?.contributions || [])
				.map(([key, value]) => ({
					key: key,
					level: value.contribution_threat_level,
					max_possible: value.max_possible,
				}))
				.filter((contrib) => contrib.max_possible > 0)
				.sort((a, b) => b.level - a.level),
		[threatContributions]
	)

	const threatContribs = useMemo(() => {
		const maxBandColor = calculateMaxColorBand(
			contributions.length > 0 ? contributions[0].level : 0
		)

		return (
			contributions.map((contrib) => ({
				key: contrib.key,
				level: contrib.level,
				color: getThreatColorBand(contrib.level, maxBandColor),
			})) || []
		)
	}, [contributions])

	return (
		threatContribs && (
			<Flex flexDirection='column'>
				<Text
					marginBlockStart={1}
					marginBlockEnd={2}
					fontSize='sm'
					color='label_color'
				>
					{t('detectionDetail.headings.threatDetails')}
				</Text>
				{threatContribs.map((contrib) => (
					<Flex
						marginBlockEnd={2}
						marginInlineEnd={1}
						flexDirection='row'
						justifyContent='space-between'
						key={contrib.key}
					>
						<Text fontSize='sm' color='body_text'>
							{t(
								'threatContributionsTooltip.' +
									contrib.key.charAt(0).toLowerCase() +
									contrib.key.slice(1)
							)}
						</Text>
						<Text color={contrib.color} fontSize='sm'>
							{formatThreatLevel(contrib.level) ?? '-'}
						</Text>
					</Flex>
				))}
			</Flex>
		)
	)
}

export default ThreatContributionsTooltip
