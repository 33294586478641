import FIGMA from '@Theme/DRO/_FIGMA'

type SentryMarkerIconProps = {
	fill?: string
	bearing?: number
}

const SentryDirectionMarkerIcon = ({
	fill = FIGMA.colors.primary,
	bearing = 0,
}: SentryMarkerIconProps) => (
	<svg
		width='234px'
		height='234px'
		viewBox='0 0 234 234'
		style={{ overflow: 'visible' }}
	>
		<defs>
			<radialGradient cy='100%' id='sentryDirectionMarkerRadialGradient'>
				<stop stopColor={fill} stopOpacity={1} offset='40%'></stop>
				<stop stopColor={`${fill}`} stopOpacity='0' offset='100%'></stop>
			</radialGradient>
			<linearGradient
				x1='50%'
				y1='70%'
				x2='50%'
				y2='30%'
				id='sentryDirectionMarkerGradient'
			>
				<stop stopColor={fill} stopOpacity={1} offset='0%' />
				<stop stopColor={`${fill}00`} stopOpacity={0} offset='100%' />
			</linearGradient>
		</defs>
		<g transform={`rotate(${bearing}, 117, 117)`}>
			<polygon
				fill='url(#sentryDirectionMarkerRadialGradient)'
				points='60 0 177 0 117 117'
			/>
		</g>
	</svg>
)

export default SentryDirectionMarkerIcon
