// Packages
import {
	useCallback,
	useContext,
	useMemo,
	memo,
	useEffect,
	useState,
} from 'react'
import { Box, Button, Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import noop from 'lodash/noop'

// Alias
import {
	formatDistance,
	formatElapsedTime,
	formatFrequency,
	formatProtocol,
	formatVendor,
} from '@/utils/formatUtils'
import useMarkerDetectionColor from '@/hooks/useMarkerDetectionColor'

// Relatives
import NotificationIcon from '../NotificationIcon'
import HeaderDetails from './HeaderDetails'
import NotificationContext from '../NotificationContext'

// Types
import type { MouseEvent } from 'react'

export type NotificationHeaderProps = {
	siteName?: string
	isSelected?: boolean
	isAlert?: boolean
	isStale?: boolean
	isTrack?: boolean
	onClick?: () => void
	onCloseAlert?: (id: string) => void
}

const NotificationHeader = ({
	siteName = 'Site Name',
	isSelected = false,
	isAlert = false,
	isStale = false,
	isTrack = false,
	onClick = noop,
	onCloseAlert = noop,
}: NotificationHeaderProps) => {
	const {
		targetId = '',
		threatLevel = 'low',
		threat = 0,
		state,
		hasDroneLocator = false,
		preventSave = false,
		displayFiltered = false,
		classification,
		siteDistance = 0,
		frequency = 0,
		rssi = 0,
		protocol = '',
		vendor = '',
		contributions = [],
		// siteZones = [],
		latitude = 0,
		longitude = 0,
		zoneName = '',
		expiredAt = 0,
	} = useContext(NotificationContext)
	const vendorParsed = formatVendor(vendor)
	const protocolParsed = formatProtocol(protocol)
	const [, setReRender] = useState(false)

	const showIcon = useMemo(
		() =>
			contributions?.length > 0 &&
			['rfSensor', 'dsx'].includes(contributions[0].sensor_type) &&
			[0, null].includes(latitude) &&
			[0, null].includes(longitude),
		[contributions, latitude, longitude]
	)

	const handleClickCloseAlert = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation()
			onCloseAlert(targetId)
		},
		[onCloseAlert, targetId]
	)

	useEffect(() => {
		if (!isStale) {
			return
		}

		// This will re-render the expired at time ago
		const intervalHandler = setInterval(() => {
			setReRender((state) => !state)
		}, 5000)

		return () => {
			clearInterval(intervalHandler)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const isWhitelisted = state === 'whitelisted'
	const markerDetectionColor = useMarkerDetectionColor(
		isWhitelisted,
		preventSave,
		classification,
		displayFiltered
	)

	return (
		<Flex
			bgColor={isSelected ? 'notifications.active_bg' : 'notifications.bg'}
			onClick={onClick}
			cursor='pointer'
		>
			<Flex alignItems='center' width='50px' position='relative'>
				<Box
					backgroundColor={`notifications.${isTrack ? 'warning' : 'transparent'}`}
					width='2px'
					height='100%'
					alignSelf='flex-start'
				/>
				<Flex alignItems='center' justifyContent='center' grow={1}>
					<NotificationIcon
						classification={classification}
						showLob={showIcon}
						color={isStale ? 'gray.400' : markerDetectionColor}
					/>
				</Flex>
				{isStale && !!expiredAt && (
					<Box
						fontSize='10px'
						position='absolute'
						top='4px'
						left='4px'
						whiteSpace='nowrap'
					>
						{formatElapsedTime(expiredAt, { addSuffix: true })}
					</Box>
				)}
			</Flex>
			<HeaderDetails
				threat={threat}
				threatLevel={threatLevel}
				hasDroneLocator={hasDroneLocator}
				isStale={isStale}
				siteDistance={
					!protocolParsed && !vendorParsed ? undefined : siteDistance
				}
				siteName={siteName}
				zoneName={zoneName}
				rssi={rssi}
			/>
			<Flex
				width='100px'
				height='100px'
				alignItems='center'
				justifyContent='center'
				direction='column'
				gap='4px'
				backgroundColor={isAlert ? 'notifications.alert' : 'transparent'}
				position='relative'
				paddingInline={0.5}
			>
				{(isAlert || isStale) && (
					<Text
						color={isStale ? 'gray.400' : 'white'}
						textTransform='uppercase'
						fontSize='xs'
						fontWeight={600}
					>
						Alert
					</Text>
				)}
				{protocolParsed && (
					<Tooltip label={protocolParsed} variant='dark'>
						<Text
							color={isStale ? 'gray.400' : 'white'}
							fontSize='xs'
							lineHeight='14px'
							maxW='95px'
							whiteSpace='nowrap'
							overflow='hidden'
							textOverflow='ellipsis'
						>
							{protocolParsed}
						</Text>
					</Tooltip>
				)}
				{!!frequency && (
					<Text
						color={isStale ? 'gray.400' : 'white'}
						fontWeight='bold'
						fontSize='sm'
						lineHeight='16px'
					>
						{formatFrequency(frequency, 2)}
					</Text>
				)}
				{!protocolParsed && !vendorParsed && (
					<Text
						color={isStale ? 'gray.400' : 'white'}
						fontWeight='bold'
						fontSize='sm'
						lineHeight='16px'
					>
						{formatDistance(siteDistance)}
					</Text>
				)}
				{vendorParsed && (
					<Tooltip label={vendorParsed} variant='dark'>
						<Text
							color={isStale ? 'gray.400' : 'white'}
							fontSize='xs'
							lineHeight='14px'
							maxW='95px'
							whiteSpace='nowrap'
							overflow='hidden'
							textOverflow='ellipsis'
						>
							{vendorParsed}
						</Text>
					</Tooltip>
				)}
				{(isAlert || isStale) && (
					<Box position='absolute' top='0' right='4px'>
						<Button
							variant='unstyled'
							minWidth='0'
							width='16px'
							height='16px'
							onClick={handleClickCloseAlert}
						>
							<Icon as={MdClose} boxSize='1rem' />
						</Button>
					</Box>
				)}
			</Flex>
		</Flex>
	)
}

export default memo(NotificationHeader)
